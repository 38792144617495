/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState,useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SendIcon from '@material-ui/icons/Send';
import axios from 'axios';
import { environment } from "../enviroments/enviroment";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import InputRut from 'html-input-rut';
import { Link } from "react-router-dom";
const useStyles2 = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));



export default function Autenticacion() {

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [numeroSerie, setNumeroSerie] = useState("");
const [rut, setRut]  = useState("");
const handleClick = () => {        window.open('https://t.me/Fairvoting_soporte_bot', '_blank');    };
  const [celular, setCelular] = useState("");
  const [user, setUser] = React.useState("");
  // eslint-disable-next-line no-undef
  
  // dar formato XX.XXX.XXX-X
  function darFormatoRUT(rut) {
    // dejar solo números y letras 'k'
    const rutLimpio = rut.replace(/[^0-9kK]/g, '');
  
    // asilar el cuerpo del dígito verificador
    const cuerpo = rutLimpio.slice(0, -1);
    const dv = rutLimpio.slice(-1).toUpperCase();
  
    if (rutLimpio.length < 2) return rutLimpio;
  
    // colocar los separadores de miles al cuerpo
    let cuerpoFormatoMiles = cuerpo
      .toString()
      .split('')
      .reverse()
      .join('')
      .replace(/(?=\d*\.?)(\d{3})/g, '$1.');
  
    cuerpoFormatoMiles = cuerpoFormatoMiles
      .split('')
      .reverse()
      .join('')
      .replace(/^[\.]/, '');
  
      
    return `${cuerpoFormatoMiles}-${dv}`;
  }
  
  // si presiona ENTER ejecutar la validación
  document.addEventListener('keypress', (e) => {
    if (e.keyCode == 13) ejecutarValidacion();
  });
  
  // oír el clic y si presiona el botón 'Validar RUT' ejecutar la validación
  document.addEventListener('click', (e) => {
    const botonValidarRUT = document.getElementById('btn-valida-rut');
  
    if (e.target === botonValidarRUT) {
      ejecutarValidacion();
    }
  });
  
  function ejecutarValidacion() {
    const rut = document.getElementById('rut').value;
    const resultado = validarRUT(rut);
    const salida = document.querySelector('.salida');
  
    if (!rut) {
      salida.innerHTML = `<p style="color: red;">Debes ingresar un RUT</p>`;
    } else if (resultado === true) {
      salida.innerHTML = `<p style="color: darkgreen;">El RUT ${rut} es válido</p>`;
    } else {
      salida.innerHTML = `<p style="color: red;">El RUT ${rut} no es válido</p>`;
    }
  
    document.getElementById('rut').value = '';
  }
  
  function validarRUT(rut) {
    // dejar solo números y letras 'k'
    const rutLimpio = rut.replace(/[^0-9kK]/g, '');
  
    // verificar que ingrese al menos 2 caracteres válidos
    if (rutLimpio.length < 2) return false;
  
    // asilar el cuerpo del dígito verificador
    const cuerpo = rutLimpio.slice(0, -1);
    const dv = rutLimpio.slice(-1).toUpperCase();
  
    // validar que el cuerpo sea numérico
    if (!cuerpo.replace(/[^0-9]/g, '')) return false;
  
    // calcular el DV asociado al cuerpo del RUT
    const dvCalculado = calcularDV(cuerpo);
  
    // comparar el DV del RUT recibido con el DV calculado
    return dvCalculado == dv;
  }
  
  function calcularDV(cuerpoRUT) {
    let suma = 1;
    let multiplo = 0;
  
    for (; cuerpoRUT; cuerpoRUT = Math.floor(cuerpoRUT / 10))
      suma = (suma + (cuerpoRUT % 10) * (9 - (multiplo++ % 6))) % 11;
  
    return suma ? suma - 1 : 'K';
  }
  
  

  const[error,setError]= useState({
    error:false,
    message:"",
  });

  const validateEmail= (email) => {
const regex =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
   return regex.test(email);
  }
  const { validate, clean, format, getCheckDigit } = require('rut.js')
  const handleSubmit = async(e) => {
    e.preventDefault();
   
    const userData = {
        rut:rut,
        email: email,
        celular: celular,
        numeroSerie:numeroSerie
      };

     
   
    axios.post(`${environment.api}votacion/autenticacionSF`, userData).then((response) => {
     // console.log('1',response.data);
     
      if(email!=null){
      if(validateEmail(email)){
        setError({
          error:false,
          message: "",
        });
       // console.log("email correcto");
  
      }
      else{
        setError({
          error:true,
          message: "Formato de email incorrecto",
      });
      }
    }
 
    
   navigate('/*****---', { state: { id: rut, color: 'green' } });

    }).catch((error) => {
      // Error
      console.log(error.response.data.message);
      if(error.response.data.message == 'Votante no encontrado'){
        Swal.fire('Estimado/a', 'Su rut no se encuentra en nuestra base de datos comuniquese con su directiva!', 'info');
       }
       if(error.response.data.message == 'Votante ya emitió todos sus votos'){
        Swal.fire('Estimado/a', 'Uste ya ha emitido todos sus votos!', 'info');
       }
       if(error.response.data.message == 'Rut Inválido'){
        Swal.fire('Estimado/a', 'El rut ingresado no es valido !', 'info');
      }
      if(error.response.data.message == 'Votación no ha comenzado'){
        Swal.fire('Estimado/a', 'El proceso de votación aún no ha comenzado según el horario establecido', 'info');
      }
      if(error.response.data.message == 'Correo vacio'){
        Swal.fire('Estimado/a', 'La autenticación es triple factor debe ingresar un correo!', 'info');
      }
      if(error.response.data.message == 'Correo inválido o equivocado'){
        Swal.fire('Estimado/a', 'El correo ingresado no pertenece al rut!', 'info');
      }
      if(error.response.data.message == 'Votación finalizada'){
        Swal.fire('Estimado/a', 'el horario del proceso de votación termino!', 'info');
      }

      if(error.response.data.message == 'Celular ya utilizado'){
        Swal.fire('Estimado/a', 'el celular que esta ingresando ya fue utilizado por otro votante!', 'info');
      }

      if(error.response.data.message == 'Email ya utilizado'){
        Swal.fire('Estimado/a', 'el correo que esta ingresando ya fue utilizado por otro votante!', 'info');
      }

      if(error.response.data.message == 'Votacion aun no aperturada'){
        Swal.fire('Estimado/a', 'El proceso de votación aun no es aperturado por ministro de fe !', 'info');
      }
      if(error.response.data.message == 'Internal server error'){
        Swal.fire('Estimado/a', 'Comuníquese con soporte. Detectamos errores en sus datos.!', 'error');
      }
      if(error.response.data.message == 'Se requiere al menos un correo o teléfono'){
        Swal.fire('Estimado/a', 'Debe ingresar un correo o un telefono los dos campos no pueden ir vacio  !', 'info');
      }

  });


    
  }
  function formateaRut(rut) {

    var actual = rut.replace(/^0+/, "");
    if (actual != '' && actual.length > 1) {
        var sinPuntos = actual.replace(/\./g, "");
        var actualLimpio = sinPuntos.replace(/-/g, "");
        var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
        var rutPuntos = "";
        var i = 0;
        var j = 1;
        for (i = inicio.length - 1; i >= 0; i--) {
            var letra = inicio.charAt(i);
            rutPuntos = letra + rutPuntos;
            if (j % 3 == 0 && j <= inicio.length - 1) {
                rutPuntos = "." + rutPuntos;
            }
            j++;
        }
        var dv = actualLimpio.substring(actualLimpio.length - 1);
        rutPuntos = rutPuntos + "-" + dv;
    }
    return rutPuntos;
}
 

  const classes = useStyles2();

  return (
    <div style={{
      textAlign: "center",
      maxWidth: "950px",
      margin: "0 auto",
      border: "1px solid #e6e6e6",
      padding: "40px 25px",
      marginTop: "50px"
    }}>
  <Box component="form" onSubmit={handleSubmit}>

  <h2><strong>Autenticación Unica</strong></h2>
  <br/>

  <TextField  id="rut"  value={rut} type="text" onChange={e => setRut(format(e.target.value ,{ dots: false }))}      fullWidth inputProps={{ maxLength: 12 }}
  
         required label="Rut(Ingresa solo los números)" placeholder="11877009-9" />
  <br/>
  <br/>
  <TextField id="email" fullWidth  type="email" value={email} error={error.error} placeholder="joselito.ramos@gmail.com"   helperText={error.message}  
  onChange={(e) => setEmail(e.target.value)}
  label="Correo" />
  <br/>
  <br/>
  <TextField fullWidth type="text" id="celular" onChange={e => setCelular(e.target.value)}   value={celular} inputProps={{ maxLength: 9 }}  label="Celular" placeholder="994099078" />
  <br/>
  <br/>
  <h2>¿Quieres obtener tu clave por Telegram?</h2>
  <h3>"Primero, ingresa tu RUT y un correo electrónico o número de celular. La clave secreta será enviada a través de uno de estos medios tambien."</h3>
  <p>Haz clic en el botón de abajo para iniciar el proceso a través de Telegram. Luego, ingresa el código recibido en el campo correspondiente.</p>
  <div style={{textAlign: "center",marginTop: "20px"}}>
  <Link onClick={handleClick} style={{ textDecoration: 'none' }}>            <button style={{ backgroundColor: "#87CEEB", color: "white", border: "none", textTransform: "uppercase", fontSize: "14px", padding: "12px 30px" }}>                <strong>Obtener código</strong>            </button>        </Link>
</div>
  <TextField fullWidth type="text" id="numeroSerie" onChange={e => setNumeroSerie(e.target.value)}   value={numeroSerie} inputProps={{ maxLength: 12 }}  label="Codigo Telegram" placeholder="6251034044" />
  <br/>
  <br/>

  <Button style={{backgroundColor: "#87CEEB",color: "white"}}
  type="submit"
  sx="{{mt:2}}"
        variant="contained"
        color="secondary"
        className={classes.button}
        endIcon={<Icon><SendIcon /></Icon>}
      >
        Continuar
      </Button>
  </Box>
 
 
<script type="module" src="https://unpkg.com/html-input-rut"></script>
  
    </div>
  )
}
