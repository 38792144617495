import React from 'react'
import { environment } from './../enviroments/enviroment';
import { Link } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import BallotIcon from '@material-ui/icons/Ballot';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import axios from 'axios';
import { useParams } from 'react-router';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles,makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useLocation,useNavigate } from "react-router-dom";
import { RiH1 } from 'react-icons/ri';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});
const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles2 = makeStyles({
  table: {
    minWidth: 700,
  },
});

function createData(Votación, Votar) {
  return { Votación, Votar };
}

export default function Votaciones() {
  const navigate = useNavigate();
  const classes = useStyles();
  const classes2 = useStyles2();
  const bull = <span className={classes.bullet}>•</span>;
  const [post, setPost] = React.useState(null);
  const [tt, setTT] = React.useState(false);
  const [tt2, setTT2] = React.useState(null);
  const [reloadUsers, setReloadUsers] = React.useState(false)
  const { rut } = useParams();

  let focusListener = null;

  const {state} = useLocation();
  const { id, color } = state; 
  


  

 React.useEffect(() => {
 
  
  const interval = setInterval(() => {
    axios.get(`${environment.api}votacion/votacionUsuario/${id}`).then(response => 
  
      
      setPost(response.data)
      
    )
  }, 2000);
  return () => clearInterval(interval);
   
  }, []);
 

  const formRef = React.useRef(null);

  const handleSubmit = () => {
    // Submit form data
    formRef.current.reset(); // Reset form fields
  };

  if (!post) return null;

 //console.log('rut que traje',post);

  return (
    <div style={{
      textAlign: "center",
      maxWidth: "950px",
      margin: "0 auto",
      border: "1px solid #e6e6e6",
      padding: "40px 25px",
      marginTop: "50px"
    }}>
      
   

       {post.length > 0 ?
      <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textPrimary" gutterBottom>
        <strong> Estimado/a rut {rut} por favor seleccione una papeleta</strong>
        <br/>
    <br/>
        </Typography>
       
        <TableContainer component={Paper}>
      <Table  className={classes.table} aria-label="customized table">
        <TableHead >
          <TableRow >
            <StyledTableCell align="center" style={{backgroundColor: "#87CEEB",color: "white"}}>Votación</StyledTableCell>
            <StyledTableCell style={{backgroundColor: "#87CEEB",color: "white"}} align="center">Votar</StyledTableCell>
          
          </TableRow>
        </TableHead>
        <TableBody>
          {post.map((row) => (
            <StyledTableRow key={row.nombre}>
              <StyledTableCell align="center">{row.nombre}</StyledTableCell>
              <StyledTableCell align="center"><Button style={{backgroundColor: "#8db600",color: "white"}} variant="contained" color="secondary"
               onClick={() => {
                navigate('/votar/', { state: { rut: id, votacionId: row.id } });

              }}
              >
  Votar
</Button></StyledTableCell>
             
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
   
      </CardContent>
      <CardActions align="center">
       
      </CardActions>
    </Card>
    : <div >

<h3>Gracias por su confianza y por emitir todos sus votos.</h3>
<br/>
<br/>
    <Link  to="https://g.page/r/CQtJqFMXVn-oEB0/review"  >
    
        <Button variant="contained" color="secondary" style={{backgroundColor: "#8db600",color: "white"}} >
        <IconButton aria-label="show 11 new notifications" color="primary">
       
       <ThumbUpIcon />
    
   </IconButton>
    &nbsp;&nbsp;Te gusto click aquí.&nbsp;&nbsp;
    </Button>
    </Link>
   </div>
  }
    </div>
  )
}
