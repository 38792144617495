import React from 'react'
import axios from 'axios';
import {format,parseISO } from 'date-fns'
import { environment } from "../enviroments/enviroment";
import Button from '@material-ui/core/Button';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import { makeStyles } from '@material-ui/core/styles';
import logo  from '../assets/images/VPTING_-removebg.png';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    backgroundColor:'#87CEEB'
  },
}));

export default function Home() {

  const classes = useStyles();

    const [post, setPost] = React.useState(null);

    React.useEffect(() => {
      axios.get(`${environment.api}votacion/1`).then((response) => {
        setPost(response.data);
      });
    }, []);
  
    if (!post) return null;

    

  return (
    <div style={{
      textAlign: "center",
      maxWidth: "950px",
      margin: "0 auto",
      border: "1px solid #e6e6e6",
      padding: "40px 25px",
      marginTop: "50px"
    }}  >
 <img style={{width: '200px'}}  src={logo} alt="" /> 
    <br/>
    
    
    
    <h1>{post.nombre}</h1>
    <br/>
    
    
    
    <br/>
    <br/>
    <br/>
    
    
    <strong><p>Fecha inicio :&nbsp;{format(parseISO(post.fechaApertura), 'dd/MM/yyyy HH:mm:ss')} <br/>
    Fecha termino :&nbsp;{format(parseISO(post.fechaCierre), 'dd/MM/yyyy HH:mm:ss')}
    </p></strong>
    <br/>
    <br/>
    <div  >
    <Link to="/">
    <Button style={{backgroundColor: "#87CEEB",color: "white"}}
        variant="contained"
        color="secondary"
        className={classes.button}
        startIcon={<HowToRegIcon />}
      >
        Autenticarse
      </Button>
      </Link>
  

    
    
   
    

      <br/>
    <br/>
    
    </div>
    
    
    </div>

    
    
  )
}
